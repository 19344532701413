/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Images
import bgFront from "assets/images/luke-chesser.jpg";
import bgBack from "assets/images/bg-sign-in-basic.jpeg";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function Information() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const dynamicColumnSpacing = matches ? 0 : 3;
  return (
    <MKBox component="section" py={1} my={1}>
      <Container>
        <Grid
          container
          item
          xs={11}
          rowSpacing={3}
          columnSpacing={dynamicColumnSpacing}
          alignItems="center"
          sx={{ mx: "auto" }}
        >
          <Grid item xs={12} lg={7}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Technical Experience"
                  description="Full stack web app development, interviewing new developers, code reviews, making
                  impactful technical decisions"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="cloud"
                  title="Cloud Solutions"
                  description="Active cloud certifications and industry experience in AWS, Azure, and Terraform"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="construction"
                  title="Cross-Industry Experience"
                  description="Experience working in the following industries: Finance, Healthcare, Federal and State Government, IT Consulting"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="campaign"
                  title="Communication"
                  description="Comfortable working with developers directly, communicating to stakeholders, and explaining difficult technical concepts."
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
          <RotatingCard>
            <RotatingCardFront
            image={bgBack}
            icon="quiz"
            title={
                <>
                Why choose us?
                </>
            }
            description={
              process.env.REACT_APP_WHY_CHOOSE_US
            }
            />
        </RotatingCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
export default Information;
