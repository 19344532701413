/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

// Images
import profilePicture from "assets/images/chris-aguirre-cropped.jpg";
import { Height, ProductionQuantityLimitsRounded } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function Profile() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const fixedHeight = matches ? null : { height: "50vh" };

  const presignedS3Url = "https://demodaytech-website-files.s3.amazonaws.com/ChristopherAguirreResume.pdf";
  return (
    <MKBox component="section" py={{ xs: 2, sm: 2 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} display="flex">
            <MKBox
              display="flex"
              justifyContent="center"
              alignContent="center"
              borderRadius="lg"
              width="100%"
              position="relative"
              zIndex={2}
              className="profile-image"
              sx={{
                backgroundImage: `url(${profilePicture})`,
                transform: "scale(0.94)",
                backgroundSize: "cover",
                height: fixedHeight,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8} justifyContent="center" py={6}>
            <Grid item xs={12} md={12} mx={{ xs: "auto", sm: 6 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">Chris Aguirre</MKTypography>
                <MKButton variant="outlined" color="info" size="small" href={process.env.REACT_APP_PERSONAL_LINKED_IN_URL} target="_blank">
                  Linked In
                </MKButton>
              </MKBox>
              {/* <Grid container spacing={3} mb={3}>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    323&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Posts
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    3.5k&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Followers
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    260&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Following
                  </MKTypography>
                </Grid>
              </Grid> */}
              <MKTypography variant="body1" fontWeight="light" color="text">
                {process.env.REACT_APP_PROFILE_DESCRIPTION}
                <MKTypography variant="body1" fontWeight="bold" color="text">
                  I have the skills to bring your software idea to market.
                </MKTypography>
                <MKTypography
                  component="a"
                  href={presignedS3Url}
                  target="_blank"
                  variant="body1"
                  fontWeight="light"
                  color="info"
                  mt={3}
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                >
                  My Resume <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
